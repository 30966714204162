import angular from "angular";

import { taskTypes } from "@skryv/core-ng1/core/constants/taskTypes";
import { searchNames } from "@skryv/core-ng1/config/search";
import {parseFieldDefinition, SearchAllType} from "@skryv/search-client";

import template from "../Search.html";

const namespace = "wrp/components/components/search/TasksSearch";

const TASK_SEARCH_SESSION_STORAGE_KEY = "taskSearchDashboard";

angular.module(namespace, []).component("wrpTasksSearch", {
  template,
  controller: function (searchOptions) {
    "ngInject";

    const $ctrl = this;

    this.$onInit = function () {
      $ctrl.searchPageName = TASK_SEARCH_SESSION_STORAGE_KEY;
    }

    // define columns for document search
    this.columnDefinitions = [
      {
        heading: "Dossier",
        select: ["dossier.label", "dossier.id"],
        field: "dossier.label",
        type: "search/keyword",
        state: () => "dossier",
        stateParams: (cell, searchHit) => {
          const dossierId =
            parseFieldDefinition("dossier.id").extract(searchHit)[0];
          return { dossierId };
        },
      },
      {
        heading: "Taak",
        type: "search/keyword",
        key: "task",
        field: {
          path: "task.name",
          options: { nesting: "task" },
        },
        sort: false,
        filter: false,
        content: `lookupAll('task',item).filter(function(task) { return task.active }).map(function(task) { return task.name})`,
        state: (cell, searchHit) => {
          const tasks = parseFieldDefinition("task").extract(searchHit);
          const activeTasks = tasks.filter(function (task) {
            return task.active;
          });
          if (
            activeTasks &&
            activeTasks.length === 1 &&
            activeTasks[0].type !== taskTypes.POP_UP
          ) {
            return "task";
          } else {
            return "dossier";
          }
        },
        stateParams: (cell, searchHit) => {
          const tasks = parseFieldDefinition("task").extract(searchHit);
          const myActiveTasks = tasks.filter(function (task) {
            return task.active;
          });
          if (
            myActiveTasks &&
            myActiveTasks.length === 1 &&
            myActiveTasks[0].type !== taskTypes.POP_UP
          ) {
            return { taskId: myActiveTasks[0].id };
          } else {
            return {
              dossierId:
                parseFieldDefinition("dossier.id").extract(searchHit)[0],
            };
          }
        },
      },
      {
        heading: "Aanvraagdatum",
        key: "aanvraagdatum",
        type: "search/date",
        format: "dd/MM/yyyy",
        field: {
          path: "document.aanvraag.aanvraagdatum",
          options: { nesting: "document" },
        },
      },
      {
        heading: "Aangevraagde categorieën",
        key: "categorieen",
        type: "search/document/multichoice",
        sort: false,
        field: {
          path: "document.aanvraag.selectieCategorieen.geselecteerdeCatgeorieen",
          options: { nesting: "document" },
        },
        choices: [
          {
            name: "binnenrenovatie",
            label: "Binnenrenovatie",
          },
          {
            name: "muurrenovatie",
            label: "Buitenmuur",
          },
          {
            name: "dakrenovatie",
            label: "Dak",
          },
          {
            name: "technischeInstallaties",
            label: "Elektriciteit en sanitair",
          },
          {
            name: "gascondensatieketel",
            label: "Gascondensatieketel",
          },
          {
            name: "buitenschrijnwerkGlas",
            label: "Ramen en deuren",
          },
          {
            name: "vloerrenovatie",
            label: "Vloer",
          },
          {
            name: "warmtepomp",
            label: "Warmtepomp",
          },
          {
            name: "warmtepompboiler",
            label: "Warmtepompboiler",
          },
          {
            name: "zonneboiler",
            label: "Zonneboiler",
          },
        ],
      },
      {
        heading: "Uitvoeringsadres",
        key: "uitvoeringsadres",
        type: "search/document/text",
        sort: false,
        field: {
          path: "document.aanvraag.uitvoeringsAdresVolledig",
          options: { nesting: "document" },
        },
      },
      {
        heading: "Opstartdatum taak",
        field: {
          path: "task.created",
          options: { nesting: "task" },
        },
        sort: false,
        filter: false,
        type: "search/date",
        content: `lookupAll('task',item).filter(function(task) { return task.active }).map(function(task) { return task.created})`,
      },
      {
        heading: "Naam aanvrager",
        key: "aanvrager",
        type: "search/document/text",
        sort: false,
        filter: false,
        field: {
          path: "document.aanvraag.naamVanDeAanvrager",
          options: { nesting: "document" },
        },
        content: `lookupAll('document.loginData',item).map(function(item) { return item.rrnOrKBONumberOfMandaatgever ? item.naamOrganisatie ? item.naamOrganisatie + " door " + item.naamOrganisatieMandaatnemer : item.voornaam + " " + item.naam + " door " + item.naamOrganisatieMandaatnemer : item.naamOrganisatie ? item.naamOrganisatie : item.voornaam + " " + item.naam})`,
      },
      {
        heading: "Type aanvrager",
        key: "typeAanvrager",
        type: "search/document/choice",
        sort: false,
        field: {
          path: "document.CategorieenStatus.typeAanvrager",
          options: { nesting: "document" },
        },
        choices: [
          {
            name: "eigenaarBewoner",
            label: "Eigenaar-bewoner",
          },
          {
            name: "svkVerhuurder",
            label: "SVK-verhuurder",
          },
          {
            name: "onderneming",
            label: "Onderneming",
          },
          {
            name: "vme",
            label: "VME",
          },
          {
            name: "onbekend",
            label: "Andere",
          },
          {
            name: "huurder",
            label: "Huurder",
          },
          {
            name: "externeInvesteerder",
            label: "Enterne investeerder",
          },
        ],
      },
      {
        heading: "Assigned to",
        key: "assignee",
        type: "search/user",
        field: {
          path: "task.assigneeUser.username",
          options: { nesting: "task" },
        },
        sort: false,
        filter: false,
        content: `lookupAll('task',item).filter(function(task) { return task.active }).map(function(task) { return task.assigneeUser})`,
      },
      {
        heading: "Organisatie",
        key: "organisation",
        type: "search/document/choice",
        field: {
          path: "document.CategorieenStatus.toegewezenAan",
          options: { nesting: "document" },
        },
        choices: [
          {
            name: "wvl",
            label: "Wonen-Vlaanderen",
          },
          {
            name: "fluvius",
            label: "Fluvius",
          },
        ],
      },
      {
        heading: "In beroep",
        key: "dossierInBeroep",
        sort: false,
        type: "search/document/choice",
        field: {
          path: "document.CategorieenStatus.dossierInBeroep",
          options: { nesting: "document" },
        },
        choices: [
          {
            name: "ja",
            label: "Ja",
          },
          {
            name: "nee",
            label: "Nee",
          },
        ],
      },
      {
        heading: "Is heropend",
        key: "dossierHeropend",
        sort: false,
        type: "search/document/choice",
        field: {
          path: "document.CategorieenStatus.dossierIsHeropend",
          options: { nesting: "document" },
        },
        choices: [
          {
            name: "ja",
            label: "Ja",
          },
          {
            name: "nee",
            label: "Nee",
          },
        ],
      },
      {
        heading: "Behandeling",
        key: "behandeling",
        type: "search/document/choice",
        sort: false,
        field: {
          path: "document.CategorieenStatus.behandeling",
          options: { nesting: "document" },
        },
        choices: [
          {
            name: "parent",
            label: "Parent",
          },
          {
            name: "child",
            label: "Child",
          },
          {
            name: "standaard",
            label: "Standaard",
          },
        ],
      },
    ];

    this.views = [
      {
        name: "Default",
        searchTerm: "_exists_:task AND dossier.label:2?-MVP-*",
        searchTermType: SearchAllType.ESQL,
        columns: [
          "dossier.label",
          "task",
          "assignee",
          "aanvraagdatum",
          "categorieen",
          "uitvoeringsadres",
          "task.created",
          // "aanvrager",
          // "typeAanvrager",
          // "organisation",
          // "beroepsDatum",
          "dossierInBeroep",
          "dossierHeropend",
          // "behandeling",
        ],
        filters: [],
        sort: {
          criterion: "document.aanvraag.aanvraagdatum",
          desc: false,
        },
      },
    ];

    this.filtersToNest = [
      { field: parseFieldDefinition("task.active"), value: true },
    ];

    this.searchDefinition = {
      key: "search/tasks",
      label: "Open taken",
      datasourceType: "es/dossiers",
      columnDefinitions: this.columnDefinitions,
      views: this.views,
      allowExport: true,
      searchOptions: searchOptions.getSearchOptionsConfigurationForKey(
        searchNames.DASHBOARD_MY_TASKS
      ),
      hiddenFilters: [
        { nested: { path: "task", queries: this.filtersToNest } },
      ],
    };
  },
});

export { template };
export default namespace;
