import angular from "angular";

import { searchNames } from "@skryv/core-ng1/config/search";
import { taskTypes } from "@skryv/core-ng1/core/constants/taskTypes";
import { parseFieldDefinition } from "@skryv/search-client";

import template from "../Search.html";

const namespace = "wrp/components/search/HistoricTaskSearch";
const HISTORIC_TASK_SEARCH_SESSION_STORAGE_KEY = "historicTaskSearchDashboard";


angular.module(namespace, []).component("wrpHistoricTasksSearch", {
  template,
  controller: function (searchOptions) {
    "ngInject";

    const $ctrl = this;

    this.$onInit = function () {
      $ctrl.searchPageName = HISTORIC_TASK_SEARCH_SESSION_STORAGE_KEY;
    }

    this.taskColumnDefinitions = [
      {
        heading: "Task",
        field: "task.name",
        type: "search/keyword",
        state: (cell, searchHit) => {
          if (searchHit.task[0].type === taskTypes.POP_UP) return "dossier";
          else return "task";
        },
        stateParams: (cell, searchHit) => {
          if (searchHit.task[0].type === taskTypes.POP_UP) {
            return {
              dossierId:
                parseFieldDefinition("dossier.id").extract(searchHit)[0],
            };
          }
          return {
            taskId: parseFieldDefinition("task.id").extract(searchHit)[0],
          };
        },
      },
      {
        heading: "Dossier",
        select: ["dossier.label", "dossier.id"],
        field: "dossier.label",
        type: "search/keyword",
        state: () => "dossier",
        stateParams: (cell, searchHit) => {
          const dossierId =
            parseFieldDefinition("dossier.id").extract(searchHit)[0];
          return { dossierId };
        },
      },
      {
        heading: "Assigned to",
        type: "search/user",
        field: {
          path: "task.assigneeUser.username",
          options: { nesting: false },
        },
      },
      {
        heading: "Start date",
        field: "task.created",
        type: "search/date",
      },
      {
        heading: "Finish date",
        field: "task.ended",
        type: "search/date",
      },
    ];

    this.views = [
      {
        name: "Default",
        columns: ["task.name", "dossier.label", "task.created", "task.ended"],
        filters: [],
        sort: {
          criterion: "task.created",
          desc: true,
        },
      },
    ];

    this.searchDefinition = {
      key: "search/tasks/v2",
      label: "tasks",
      datasourceType: "es/tasks",
      columnDefinitions: this.taskColumnDefinitions,
      views: this.views,
      allowExport: true,
      searchOptions: searchOptions.getSearchOptionsConfigurationForKey(
        searchNames.SEARCH_HISTORIC_TASKS
      ),
      hiddenFilters: [
        { field: parseFieldDefinition("task.active"), value: false },
      ],
    };
  },
});

export { template };
export default namespace;
